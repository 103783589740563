<template>
	<div class="card card-custom card-stretch gutter-b" ref="siteListContainer">
		<div class="card-header border-1 py-1 d-flex align-items-center" style="height: 55px; min-height: 40px">
			<div>
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label"> Sites </span>
				</h3>
			</div>
			<div :class="[{ 'col-9 p-0': widgetWidth < 500 }, 'd-flex align-items-center']">
				<div v-if="$route.name == 'sitelist'">
					<router-link :to="{ name: 'sitelistgraphs' }">
						<b-button class="mr-3" variant="outline-secondary" size="sm">Show Graphs</b-button>
					</router-link>
				</div>
				<div class="search-box mr-3">
					<b-form-input class="search-bar" size="sm" v-model="searchText" placeholder="Search" debounce="300" />
					<b-icon-search class="search-icon" />
				</div>
				<div v-if="filteredSites.length > 0 && sites.length > perPage" class="d-flex align-items-center">
					<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredSites.length" :per-page="perPage" />
				</div>
			</div>
		</div>
		<div :class="[{ 'table-container': widgetWidth < 500 }, 'card-body px-0 py-0']">
			<ITCSpinner :loaded="loaded">
				<!--begin::Table Use if full width-->
				<b-table
					v-if="widgetWidth > 500"
					:items="filteredSites"
					:fields="[
						{
							key: 'Name',
							label: '',
							thClass: 'site-list-names',
							tdClass: 'list-name',
							sortable: true,
						},
						{ key: 'max_status', label: '', tdClass: 'device-icons', sortable: true },
					]"
					:current-page="currentPage"
					sort-icon-left
					:per-page="perPage"
					:sticky-header="fullpage ? '75vh' : filteredSites.length > 7"
					small
				>
					<template #cell(Name)="data">
						<span>
							<img class="mr-2" width="20" :src="'/media/img/ICON-' + (data.item.icon ? data.item.icon : 'dish') + '.png'" />
							<router-link :to="{ name: 'site', params: { id: data.item.SiteId } }">
								{{ data.value }}
							</router-link>
						</span>
					</template>
					<template #cell(max_status)="data">
						<div class="d-flex flex-wrap align-items-center">
							<SiteListIcons
								v-bind="{
									id: data.item.SiteId + '_' + i,
									deviceType: (deviceTypes[device.TypeId] && deviceTypes[device.TypeId].Name) || 'undefined',
									deviceIcon: deviceIcon[device.TypeId],
									device,
								}"
								v-for="(device, i) in data.item.devices"
								:key="data.item.SiteId + '-_' + device.DID"
							/>
						</div>
					</template>
				</b-table>

				<!-- END TABLE -->
				<!-- Use divs if widget 1 column -->

				<div v-else class="p-3">
					<div v-for="site in filteredSites" :key="site.SiteId" class="d-flex small-site my-1 pb-1">
						<div class="mr-2">
							<img width="15px" :src="'/media/img/ICON-' + (site.icon ? site.icon : 'dish') + '.png'" />
						</div>
						<div>
							<router-link :to="{ name: 'site', params: { id: site.SiteId } }">
								{{ site.Name }}
							</router-link>
							<div class="d-flex flex-wrap mt-1" v-if="site.devices">
								<SiteListIcons
									v-for="(device, i) in site.devices"
									v-bind="{
										id: site.SiteId + '_' + i,
										device,
										deviceType: deviceTypes[device.TypeId].Name,
										deviceIcon: deviceIcon[device.TypeId],
										iconClass: 'mb-1',
									}"
									:key="site.SiteId + '-_' + device.DID"
								/>
							</div>
						</div>
					</div>
				</div>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_READY_STATE } from '@/core/services/store/ready.module';
import swMixin from '@/core/services/mixins/serviceworker.mixin';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';
import SiteListIcons from '@/view/content/widgets/components/SiteListIcons.vue';

export default {
	name: 'SiteList',
	mixins: [swMixin],
	props: {
		fullpage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			sites: [],
			searchText: '',
			currentPage: 1,
			perPage: 100,
			loaded: false,
			deviceTypes: null,
			deviceIcon: {
				0: 'fa-signal',
				1: 'fa-square-o',
				2: 'fa-bolt',
				5: 'fa-phone',
				6: 'fa-signal',
				8: 'fa-sitemap',
				9: 'fa-object-ungroup',
				10: 'fa-fire',
				11: 'fa-eye',
				12: 'fa-signal',
				13: 'fa-bolt',
				14: 'fa-wifi',
				15: 'fa-filter',
			},
			widgetWidth: null,
		};
	},
	components: {
		ITCSpinner,
		SiteListIcons,
	},
	computed: {
		...mapGetters(['layoutConfig']),
		filteredSites() {
			if (!this.searchText) return this.sites;
			return this.sites.filter(site => {
				return site.Name.toLowerCase().includes(this.searchText.toLowerCase());
			});
		},
	},
	methods: {
		addParamsToLocation(params) {
			history.pushState(
				{},
				null,
				this.$route.path +
					'?' +
					Object.keys(params)
						.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
						.join('&')
			);
		},
		setMaxStatus(dd) {
			var alarm = 0;
			var warning = 1;
			var ok = 2;
			var offline = 3;
			var deactivated = 4;
			var unknown = 5;

			for (var i = 0; i < dd.length; i++) {
				dd[i].max_status = 6;
				if (dd[i].devices) {
					Object.keys(dd[i].devices).forEach(k => {
						var d = dd[i].devices[k];
						if (d.TypeId == 0) {
							if (d.status == 'ALARM' && dd[i].max_status > alarm) {
								dd[i].max_status = alarm;
							}
							if (d.status == 'WARNING' && dd[i].max_status > warning) {
								dd[i].max_status = warning;
							}
							if (d.status == 'OK' && dd[i].max_status > ok) {
								dd[i].max_status = ok;
							}
							if (d.status == 'OFFLINE' && dd[i].max_status > offline) {
								dd[i].max_status = offline;
							}
							if (d.status == 'DEACTIVATED' && dd[i].max_status > deactivated) {
								dd[i].max_status = deactivated;
							}
							if (d.status == 'UNKNOWN' && dd[i].max_status > unknown) {
								dd[i].max_status = unknown;
							}
						}
					});
				}
			}
			return dd;
		},
	},
	created() {
		this.loaded = false;
		Promise.all([this.$http.get('sitelist'), this.$http.get('/devicetypes')]).then(responses => {
			this.sites = Object.freeze(this.setMaxStatus(responses[0].data.data));
			this.deviceTypes = responses[1].data.data;
			this.$store.commit(SET_READY_STATE, { component: 'SiteList', ready: true });
			this.loaded = true;
		});
		this.setSWListener('sitelist', data => {
			this.sites = Object.freeze(this.setMaxStatus(data.data));
		});
	},
	mounted() {
		this.widgetWidth = this.$refs.siteListContainer.clientWidth;
	},
};
</script>

<style lang="scss" scoped>
.hidden-header {
	display: none;
}
.card-header {
	border-bottom: 0px !important;
}
::v-deep .site-list-names {
	width: 400px;
	padding-left: 30px;
	background-position: left 35px center !important;
}
::v-deep .list-name {
	vertical-align: middle;
	padding-left: 30px;
	width: 400px;
}
.search-box {
	position: relative;
	width: 300px;
}
.search-bar {
	padding-left: 30px;
	border-color: #3699ff;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.pagination {
	margin-bottom: 0;
}
.table-container {
	height: 200px;
	overflow-y: scroll;
}
.small-site {
	border-bottom: 1px solid #ebedf3;
}
</style>
