var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"siteListContainer",staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-1 py-1 d-flex align-items-center",staticStyle:{"height":"55px","min-height":"40px"}},[_vm._m(0),_c('div',{class:[{ 'col-9 p-0': _vm.widgetWidth < 500 }, 'd-flex align-items-center']},[(_vm.$route.name == 'sitelist')?_c('div',[_c('router-link',{attrs:{"to":{ name: 'sitelistgraphs' }}},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"outline-secondary","size":"sm"}},[_vm._v("Show Graphs")])],1)],1):_vm._e(),_c('div',{staticClass:"search-box mr-3"},[_c('b-form-input',{staticClass:"search-bar",attrs:{"size":"sm","placeholder":"Search","debounce":"300"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('b-icon-search',{staticClass:"search-icon"})],1),(_vm.filteredSites.length > 0 && _vm.sites.length > _vm.perPage)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-pagination',{staticClass:"pagination",attrs:{"total-rows":_vm.filteredSites.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])]),_c('div',{class:[{ 'table-container': _vm.widgetWidth < 500 }, 'card-body px-0 py-0']},[_c('ITCSpinner',{attrs:{"loaded":_vm.loaded}},[(_vm.widgetWidth > 500)?_c('b-table',{attrs:{"items":_vm.filteredSites,"fields":[
					{
						key: 'Name',
						label: '',
						thClass: 'site-list-names',
						tdClass: 'list-name',
						sortable: true,
					},
					{ key: 'max_status', label: '', tdClass: 'device-icons', sortable: true } ],"current-page":_vm.currentPage,"sort-icon-left":"","per-page":_vm.perPage,"sticky-header":_vm.fullpage ? '75vh' : _vm.filteredSites.length > 7,"small":""},scopedSlots:_vm._u([{key:"cell(Name)",fn:function(data){return [_c('span',[_c('img',{staticClass:"mr-2",attrs:{"width":"20","src":'/media/img/ICON-' + (data.item.icon ? data.item.icon : 'dish') + '.png'}}),_c('router-link',{attrs:{"to":{ name: 'site', params: { id: data.item.SiteId } }}},[_vm._v(" "+_vm._s(data.value)+" ")])],1)]}},{key:"cell(max_status)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-wrap align-items-center"},_vm._l((data.item.devices),function(device,i){return _c('SiteListIcons',_vm._b({key:data.item.SiteId + '-_' + device.DID},'SiteListIcons',{
								id: data.item.SiteId + '_' + i,
								deviceType: (_vm.deviceTypes[device.TypeId] && _vm.deviceTypes[device.TypeId].Name) || 'undefined',
								deviceIcon: _vm.deviceIcon[device.TypeId],
								device: device,
							},false))}),1)]}}],null,false,1773357215)}):_c('div',{staticClass:"p-3"},_vm._l((_vm.filteredSites),function(site){return _c('div',{key:site.SiteId,staticClass:"d-flex small-site my-1 pb-1"},[_c('div',{staticClass:"mr-2"},[_c('img',{attrs:{"width":"15px","src":'/media/img/ICON-' + (site.icon ? site.icon : 'dish') + '.png'}})]),_c('div',[_c('router-link',{attrs:{"to":{ name: 'site', params: { id: site.SiteId } }}},[_vm._v(" "+_vm._s(site.Name)+" ")]),(site.devices)?_c('div',{staticClass:"d-flex flex-wrap mt-1"},_vm._l((site.devices),function(device,i){return _c('SiteListIcons',_vm._b({key:site.SiteId + '-_' + device.DID},'SiteListIcons',{
									id: site.SiteId + '_' + i,
									device: device,
									deviceType: _vm.deviceTypes[device.TypeId].Name,
									deviceIcon: _vm.deviceIcon[device.TypeId],
									iconClass: 'mb-1',
								},false))}),1):_vm._e()],1)])}),0)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label"},[_vm._v(" Sites ")])])])}]

export { render, staticRenderFns }